<template>
  <div id="feedback">
    <h2>Laisse un commentaire</h2>
    <p>Une recommendation, une demande, un problème à signaler, une incompréhension... N'hésite pas !</p>
    <div>
      <label for="message">Message&nbsp;:</label>
      <textarea
        v-model="message"
        id="message"
        rows="15"
      />
      <label class="pj">
        Pièce-jointe&nbsp;:
        <input
          type="file"
          ref="fileupload"
          @change="onFileChange($event, )"
        />
      </label>
      <div class="button-container">
        <button @click="save">Envoyer</button>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/modules/axios";
import { catchError } from "@/utils";

export default {
  name: "Feedback",
  data() {
    return {
      message: "",
      pj: null,
    };
  },
  methods: {
    onFileChange(e) {
      this.pj = (e.target.files || e.dataTransfer.files)[0];
    },
    save() {
      const formData = new FormData();
      formData.append("message", this.message);
      if (this.pj) {
        formData.append("pj", this.pj);
      }

      Api()
        .post("/feedback/", formData)
        .then(() => {
          this.$toast("Commentaire envoyé. Merci !");
          this.message = "";
          this.pj = null;
          this.$refs.fileupload.value = null;
        })
        .catch(catchError);
    },
  },
};
</script>

<style lang="scss">
#feedback {
  label {
    font-weight: bold;
  }
  textarea {
    width: 100%;
  }
  .pj {
    display: block;
    margin-top: 1rem;
  }
}
</style>
