<template>
  <div
    class="img-container"
  >
    <div>
      <h2>Vous semblez perdu</h2>
      <img :src="require('@/assets/lost.png', )" />
      <a
        class="blue-link"
        @click="$router.go(-1,)"
      >
        Retour
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style lang="scss">

</style>
