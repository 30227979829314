<template>
  <div v-if="!user.beta_accepted && !user.subscribe_asked">
    <h2>
      Bienvenue !
    </h2>
    <div>
      Tentes ta chance pour intégrer la beta privée afin d'avoir un accès
      anticipé au système de suivi de tes performances.
      <br /><br />
      Tu pourras créer des séances, exercices, suivre tes évolutions en performances, pesées, photos...
    </div>
    <div class="button-container">
      <router-link :to="{ name: 'beta', }">
        <button>S'inscrire à l'accès anticipé</button>
      </router-link>
    </div>
    <div class="button-container">
      <router-link :to="{ name: 'radio', }">
        <button class="secondary">Passer une musique</button>
      </router-link>
    </div>
  </div>
  <div v-else-if="user.is_new && user.beta_accepted">
    <h2>
      Bienvenue !
    </h2>
    <div class="button-container">
      <button @click="startTuto">Lancer le tutoriel</button>
    </div>
    <div class="button-container">
      <router-link :to="{ name: 'seances', }">
        <button class="secondary">Créer ma première séance</button>
      </router-link>
    </div>
    <div class="button-container">
      <router-link :to="{ name: 'radio', }">
        <button class="secondary">Passer une musique</button>
      </router-link>
    </div>
  </div>
  <div v-else-if="user.is_new && !user.beta_accepted">
    <h2>
      Bienvenue !
    </h2>
    <div class="button-container">
      <router-link :to="{ name: 'radio', }">
        <button class="secondary">Passer une musique</button>
      </router-link>
    </div>
  </div>
  <div v-else>
    <h2>Actualités</h2>
      <ul>
        <li
          v-for="a in actus"
          :key="a.id"
        >
          <!-- <div class="date">{{ a.date_debut | moment("DD/MM/yyyy") }}</div> -->
          <b>{{ a.titre }}</b> &middot; <span class="date">{{ a.date_debut | moment("from", "now") }}</span>
          <p>
            {{ a.texte }}
          </p>
        </li>
      </ul>
      <Pagination
        v-model="currentPage"
        :total-results="resultsCount"
        :display-one-page-text="false"
        @paginate="getInfos"
      />
    </div>
</template>

<script>
import Api from "@/modules/axios";
import Pagination from "@/components/Pagination.vue";
import { mapGetters } from "vuex";

export default {
  name: "Accueil",
  components: {
    Pagination,
  },
  data() {
    return {
      actus: [],
      currentPage: 1,
      resultsCount: 1,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  mounted() {
    if (!this.user.beta_accepted) {
      if (this.user.subscribe_asked) {
        this.$store.dispatch("faceExpression", "happy");
        this.$store.commit(
          "setAvatarText",
          `Ta demande d'accès anticipé a bien été prise en compte.
          <br/><br/>En attendant, tu peux choisir la musique !`,
        );
      } else {
        this.$store.dispatch("faceExpression", "happy");
        this.$store.commit("setAvatarText", `
          Salut ! Je suis <b>absolu</b>ment ravis de te connaître !
          <br /><br />
          Grâce à moi, tu vas pouvoir choisir la musique qui passe à l'Absolu.
          <br /><br />
          Mais c'est pas tout !
        `);
      }
    } else if (this.user.beta_accepted && this.user.is_new) {
      this.$store.dispatch("faceExpression", "happy");
      this.$store.commit("setAvatarText",
        `Salut ! Je suis <b>absolu</b>ment ravis de te connaître !
        <br /><br />
        Grâce à moi, tu vas pouvoir suivre l'évolution de tes performances,
        tout en choisissant la musique. <br/><br/>
        Pour débuter, je te conseille de suivre le tutoriel.
        `);
    } else if (this.user.beta_accepted || !this.user.is_new) {
      this.$store.dispatch("faceExpression", "very-happy");
      this.$store.commit("setAvatarText", "Voici les dernières nouvelles de l'Absolu");
      this.getInfos();
    }
  },
  methods: {
    getInfos() {
      return Api().get(`/actualite/?page=${this.currentPage}`)
        .then((res) => {
          this.actus = res.data.results;
          this.resultsCount = res.data.count;
        });
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/variables.scss" as var;

.actus {
  li {
    .date {
      margin-bottom: 0.5rem;
      font-weight: bold;
    }
    & + li {
      margin-top: 2rem;
    }
  }
}

// .content-page-accueil {
//   #avatar_container {
//     flex-direction: column;
//     bottom: 1rem;
//     align-items: center;
//     right: 0;
//     width: 100%;
//   }
//   #avatar_zone {
//     align-self: center;
//     border-color: var.$border-gray;
//     min-width: 60vw;
//     min-height: 60vw;
//   }
//   .speak-text {
//     max-width: 95vw;
//     > div {
//       font-size: 1rem;
//     }
//     > div::before {
//       transform: rotate(90deg);
//       top: unset;
//       bottom: -1rem;
//       left: 50%;
//     }

//     > div::after {
//       transform: rotate(90deg);
//       top: unset;
//       bottom: -0.8rem;
//       left: calc(50% + 1px);
//     }
//   }
//   #avatar_zone {
//     width: 50vw;
//     height: 50vw;
//   }
// }
</style>
