<template>
  <div id="beta_subscription">
    <h2>Inscription à l'accès anticipé</h2>
    <p>
      Tu pourras créer des séances, exercices, suivre tes évolutions en
      performances, pesées, photos...
    </p>
    <div>
      <div class="field">
        <label>
          <strong>Quand t'entraînes-tu ?&nbsp;:</strong>
        </label>
        <ul>
          <li>
            <input
              v-model="horaires"
              id="matin"
              type="radio"
              value="matin"
              name="horaires"
            />
            <label for="matin">Le matin</label>
          </li>
          <li>
            <input
              v-model="horaires"
              id="apres_midi"
              type="radio"
              value="apres-midi"
              name="horaires"
            />
            <label for="apres_midi">L'après-midi</label>
          </li>
          <li>
            <input
              v-model="horaires"
              id="soir"
              type="radio"
              value="soir"
              name="horaires"
            />
            <label for="soir">Le soir</label>
          </li>
          <li>
            <input
              v-model="horaires"
              id="depend"
              type="radio"
              value="ca-depend"
              name="horaires"
            />
            <label for="depend">Ça dépend</label>
          </li>
        </ul>
      </div>
      <div class="field">
        <label>
          <strong>Habituellement, tu t'entraînes&nbsp;:</strong>
        </label>
        <ul>
          <li>
            <input
              v-model="groupe"
              id="seul"
              type="radio"
              value="seul"
              name="groupe"
            />
            <label for="seul">Seul</label>
          </li>
          <li>
            <input
              v-model="groupe"
              id="groupe"
              type="radio"
              value="groupe"
              name="groupe"
            />
            <label for="groupe">En groupe</label>
          </li>
        </ul>
      </div>
      <div class="button-container">
        <button
          @click="submit"
          :disabled="!groupe || !horaires"
        >
          Je tente ma chance
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/modules/axios";
import { mapGetters } from "vuex";

export default {
  name: "BetaSubscription",
  data() {
    return {
      groupe: null,
      horaires: null,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    submit() {
      Api()
        .post("/utilisateur/subscribe/", {
          groupe: this.groupe,
          horaires: this.horaires,
        })
        .then((res) => {
          if (res.data.ok) {
            this.$toast("Inscription prise en compte. Vous recevrez un mail en cas d'acceptation");
          } else {
            this.$toast("Inscription déjà effectuée", true);
          }

          this.$store.commit("setUser", res.data.user);
          this.$router.push({ name: "accueil" });
        });
    },
  },
  mounted() {
    if (this.user.subscribe_asked || this.user.beta_accepted) {
      this.$toast("Inscription déjà effectuée", true);
      this.$router.push({ name: "accueil" });
    }

    this.$store.dispatch("faceExpression", "look-up-left");
  },
};
</script>

<style lang="scss">
#beta_subscription {
  .field + .field {
    margin-top: 2rem;
  }
}
</style>
